import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { RolesPermissionService } from '../providers/authentication/roles.service';
import { AccountService } from '../providers/common-services/account.service';

const ROLES = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    LEARNER: 'learner',
};

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keyCloakService: KeycloakService,
        private accountService: AccountService,
        private rolesPermissionService: RolesPermissionService
    ) {
        super(router, keyCloakService);
    }

    // public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //     // Force the user to log in if currently unauthenticated.
    //     if (!this.authenticated) {
    //         await this.keycloak.login({
    //             redirectUri: window.location.origin + state.url,
    //         });
    //     }

    //     // Get the roles required from the route.
    //     const requiredRoles = route.data.roles;
    //     let keyCloakInstance: any = this.keycloak.getKeycloakInstance();
    //     let parseToken = keyCloakInstance?.tokenParsed;
    //     let clientId = keyCloakInstance.clientId;
    //     let userRole = '';
    //     if (parseToken?.resource_access?.webapp1) {
    //         userRole = parseToken?.resource_access?.webapp1.roles[0];
    //     }

    //     this.accountService.attributes = keyCloakInstance.profile.attributes;
    //     let ma_don_vi = this.accountService.getMetadata().ma_don_vi;
    //     if (ma_don_vi != '103') {
    //         await Swal.fire('Tài khoản không được cấp phép!', '', 'error');
    //         this.keycloak.logout();
    //         return false;
    //     }

    //     return true;

    //     // Allow the user to to proceed if no additional roles are required to access the route.
    //     // if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
    //     //   return true;
    //     // }

    //     // // Allow the user to proceed if all the required roles are present.
    //     // return requiredRoles.every((role) => this.roles.includes(role));
    // }

    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        const everyRoles: string[] = route.data.everyRoles || [];
        const someRoles: string[] = route.data.someRoles || [];

        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keyCloakService.login({
                redirectUri: window.location.origin + state.url,
            });
        }

        // Check Roles
        let userRoles = this.accountService.getUserRoles();
        let isPassEvery: boolean = false;
        let isPassSome: boolean = false;

        // Check everyRoles
        if (Array.isArray(everyRoles) && everyRoles.length > 0)
            isPassEvery = everyRoles.every((role) => userRoles.includes(role));
        else isPassEvery = true;

        // Check someRoles
        if (isPassEvery)
            if (Array.isArray(someRoles) && someRoles.length > 0)
                isPassSome = someRoles.some((role) => userRoles.includes(role));
            else isPassSome = true;

        if (isPassEvery && isPassSome) {
            this.accountService.updateUserdata();
            this.rolesPermissionService.changeForRole(userRoles);
            return true;
        }
        await Swal.fire('Tài khoản không được cấp phép!', '', 'error');
        this.keyCloakService.logout();
        return false;
    }
}
