import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../common-services/account.service';
import { HostnameService } from '../common-services/hostname.service';

@Injectable({ providedIn: 'root' })
export class KhtcBctcTT99Service {
    private _data: any[] = [];
    private _dataSub: Subject<any[]> = new Subject();

    public get data(): any[] {
        return this._data;
    }
    public set data(value: any[]) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any[]> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetchTT99() {
        let result: any = await this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/khtc-bctc-tt99/is-have-tt99', {
                headers: {
                    apikey: this.accountService.apikey,
                },
            })
            .toPromise();

        if (result.status == 'error') {
            this.data = [];
            return this.data;
        }
        this.data = result.orgs;
        return this.data;
    }

    async fetchIfNotExists() {
        if (Array.isArray(this._data) && this._data.length > 0) return this.data;
        return await this.fetchTT99();
    }
}
