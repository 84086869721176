import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../common-services/account.service';
import { HostnameService } from '../common-services/hostname.service';
import { bcth_khtc_bcqt_check_for_role } from './roles.bcth.khtc.bcqt.utils';
import { bcth_khtc_bcqtr_check_for_role } from './roles.bcth.khtc.bcqtr.utils';
import { bcth_khtc_bctc_check_for_role } from './roles.bcth.khtc.bctc.utils';
import { bcth_khtc_hntc_check_for_role } from './roles.bcth.khtc.hntc.utils';
import { dashboard_check_for_role } from './roles.dashboard.utils';
import { MCODES, menu_items_check_for_role } from './roles.menu.utils';

@Injectable({ providedIn: 'root' })
export class RolesPermissionService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    get data() {
        return this._data;
    }
    get dataSub() {
        return this._dataSub;
    }

    constructor(
        private accountService: AccountService,
        private hostnameService: HostnameService,
        protected readonly keyCloakService: KeycloakService
    ) {
        this._data.clear();
        this._data.set('roles', []);
        this._data.set('menu_items', [MCODES.dashboard, MCODES.taikhoan]);
        this._data.set('bcth_khtc_hntc', {});
        this._data.set('bcth_khtc_bctc', {});
        this._data.set('bcth_khtc_bctc_tt09', {});
        this._data.set('bcth_khtc_bcqt', {});
        this._data.set('bcth_khtc_bcqtr', {});
        this._data.set(MCODES.dashboard, {});
    }

    changeForRole(newRoles: string[]) {
        let currentValues: any = this._data.get('roles');
        if (currentValues.length == newRoles.length && newRoles.every((role: string) => currentValues.includes(role)))
            return;

        this._data.set('roles', newRoles);
        this._data.set('menu_items', menu_items_check_for_role(newRoles));
        this._data.set('bcth_khtc_hntc', bcth_khtc_hntc_check_for_role(newRoles));
        this._data.set('bcth_khtc_bctc', bcth_khtc_bctc_check_for_role(newRoles));
        this._data.set('bcth_khtc_bctc_tt09', bcth_khtc_bctc_check_for_role(newRoles));
        this._data.set('bcth_khtc_bcqt', bcth_khtc_bcqt_check_for_role(newRoles));
        this._data.set('bcth_khtc_bcqtr', bcth_khtc_bcqtr_check_for_role(newRoles));
        this._data.set(MCODES.dashboard, dashboard_check_for_role(newRoles));
        this._dataSub.next(this._data);
    }
}
