import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { AccountService } from 'src/app/providers/common-services/account.service';
export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string | Array<string>;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string | Array<string>;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string | Array<string>;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}
var checkroles: Routes = [];

export const HOC_BONG = {
    state: 'scholarship',
    name: 'scholarship_management',
    type: 'sub',
    icon: 'school',
    children: [
        { state: 'scholarship-list', name: 'scholarship_list', type: 'link' },
        { state: 'scholarship-registration', name: 'scholarship_registration', type: 'link' },
    ],
};

export const UNG_VIEN = {
    state: 'candidate',
    name: 'candidate_management',
    type: 'sub',
    icon: 'person',
    children: [
        { state: 'candidate-list', name: 'candidate_list', type: 'link' },
        // { state: 'candidate-registration', name: 'candidate_registration', type: 'link' },
        { state: 'candidate-evaluate', name: 'candidate_evaluate', type: 'link' },
    ],
};

export const UNG_VIEN_DON_VI = {
    state: 'candidate',
    name: 'candidate_management',
    type: 'sub',
    icon: 'person',
    children: [
        { state: 'candidate-list', name: 'candidate_list', type: 'link' },
        { state: 'candidate-registration', name: 'candidate_registration', type: 'link' },
    ],
};

export const NHA_TAI_TRO = {
    state: 'sponsor',
    name: 'sponsor_management',
    type: 'sub',
    icon: 'work',
    children: [
        { state: 'sponsor-list', name: 'sponsor_list', type: 'link' },
        { state: 'sponsor-registration', name: 'sponsor_registration', type: 'link' },
    ],
};

// export const TIEU_CHI_DANH_GIA = {
//     state: 'evaluation',
//     name: 'evaluation_management',
//     type: 'sub',
//     icon: 'ballot',
//     children: [
//         { state: 'evaluation-list', name: 'evaluation_list', type: 'link' },
//         { state: 'evaluation-registration', name: 'evaluation_registration', type: 'link' },
//     ],
// };

export const BAO_CAO_TONG_HOP = {
    state: 'synthetic',
    name: 'Reporting',
    type: 'sub',
    icon: 'library_books',
    children: [
        { state: 'bao-cao-ban-khtc', name: 'bao_cao_ban_khtc', type: 'link' },
        // { state: 'bao-cao-du-toan', name: 'bao_cao_du_toan', type: 'link' },
    ],
};

// export const HOI_NGHI_TAI_CHINH = {
//     state: ['synthetic', 'hoi-nghi-tai-chinh'],
//     name: 'hoi_nghi_tai_chinh',
//     type: 'link',
//     icon: 'work',
// };

export const BC_KHTC_HOI_NGHI_TAI_CHINH = {
    state: ['synthetic', 'bao-cao-hntc'],
    name: 'hoi_nghi_tai_chinh',
    type: 'link',
    icon: 'payments',
};

export const TAI_KHOAN = {
    state: 'account',
    name: 'Accounts',
    type: 'sub',
    icon: 'account_circle',
    children: [{ state: 'editaccount', name: 'Edit Account', type: 'link' }],
};

export const DASHBOARD = {
    state: 'dashboards',
    name: 'Dashboards',
    type: 'link',
    icon: 'av_timer',
};

export const MENUITEMS = [
    DASHBOARD,

    // KHTC
    // {
    //     state: "finance",
    //     name: "Finance",
    //     type: "link",
    //     icon: "attach_money",
    // },
    // {
    //     state: "estimates",
    //     name: "Estimates",
    //     type: "link",
    //     icon: "drive_file_rename_outline",
    // },
    // {
    //     state: "invest",
    //     name: "Invest",
    //     type: "link",
    //     icon: "trending_up",
    // },
    // {
    //     state: "asset",
    //     name: "Asset",
    //     type: "link",
    //     icon: "web_asset",
    // },

    // KHTC
    // HOI_NGHI_TAI_CHINH,
    BC_KHTC_HOI_NGHI_TAI_CHINH,

    // Files
    {
        state: 'files',
        name: 'Files',
        type: 'sub',
        icon: 'archive',
        children: [{ state: 'filesdownloaded', name: 'Files Downloaded', type: 'link' }],
    },
    // Utilities
    {
        state: 'utitlities',
        name: 'Utitlities',
        type: 'sub',
        icon: 'extension',
        children: [{ state: 'utilitiesaddresslist', name: 'Address Normalization', type: 'link' }],
    },
    TAI_KHOAN,
];

@Injectable({ providedIn: 'root' })
export class MenuItems {
    constructor(private accountService: AccountService) {}

    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}

export function updateMenuItemsRole() {}
