// CONSTABCE
export const MCODES = {
    dashboard: 'dashboard', // Bảng điều khiển

    khtc_hntc: 'khtc_hntc', // Hội nghị tài chính
    khtc_bctc: 'khtc_bctc', // Báo cáo tài chính
    khtc_bctc_tt99: 'bcth_bckhtc_bctc_tt99', // Báo cáo tài chính 99
    khtc_bcqt: 'khtc_bcqt', // Báo cáo quyết toán
    khtc_bcqtr: 'khtc_bcqtr', // Báo cáo quản trị 

    luutru: 'luutru', // Lưu trữ
    taikhoan: 'taikhoan', // Tài khoản
};

// ROLES

// prettier-ignore
export const MENU_ITEMS_ROLES: Map<string, string[]> = new Map([
    ['admin', [
        MCODES.dashboard, 
        MCODES.khtc_hntc, 
        MCODES.khtc_bctc,
        MCODES.luutru, MCODES.taikhoan
    ]],

    // KHTC HNTC
    ['vnuhcm.khtc.hntc.chuyenvien', [
        MCODES.dashboard, 
        MCODES.khtc_hntc, 
        MCODES.khtc_bctc,
        MCODES.khtc_bctc_tt99,
        MCODES.khtc_bcqt,
        MCODES.khtc_bcqtr,
        MCODES.luutru, MCODES.taikhoan
    ]],
    ['vnuhcm.khtc.hntc.lanhdao', [
        MCODES.dashboard, 
        MCODES.khtc_hntc, 
        MCODES.khtc_bctc,
        MCODES.khtc_bctc_tt99,
        MCODES.khtc_bcqt,
        MCODES.khtc_bcqtr,
        MCODES.luutru, MCODES.taikhoan
    ]],

    // KHTC XDDT
    ['vnuhcm.khtc.xddt.chuyenvien', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],
    ['vnuhcm.khtc.xddt.lanhdao', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],

    // KHTC GDT
    ['vnuhcm.khtc.gdt.chuyenvien', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],
    ['vnuhcm.khtc.gdt.lanhdao', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],

    // KHTC QT
    ['vnuhcm.khtc.qt.chuyenvien', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],
    ['vnuhcm.khtc.qt.lanhdao', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],

    // KHTC TTQT
    ['vnuhcm.khtc.ttqt.chuyenvien', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],
    ['vnuhcm.khtc.ttqt.lanhdao', [
        MCODES.dashboard, 
        MCODES.luutru, MCODES.taikhoan
    ]],
]);

// FUNCTIONS
export function menu_items_check_for_role(roles: string[]) {
    let menu_items: Set<string> = new Set();
    roles.forEach((role: string) => {
        let menu_for_role = MENU_ITEMS_ROLES.get(role);
        if (!Array.isArray(menu_for_role)) return;
        menu_for_role.forEach((item: string) => menu_items.add(item));
    });
    return [...menu_items];
}
