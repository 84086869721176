import { Component, OnInit } from '@angular/core';
import { KhtcBctcTT99Service } from './providers/bao-cao-ban-khtc-bctc-tt99/khtc-bctc-tt-99.service';
import { GeneralDataService } from './providers/common-services/general-data.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    constructor(private generalDataService: GeneralDataService) {}

    async ngOnInit() {
        this.generalDataService.fetch();
    }
}
