import { ChangeDetectorRef, Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { RolesPermissionService } from 'src/app/providers/authentication/roles.service';
import { Subscription } from 'rxjs';
import { MenuItems } from 'src/app/providers/authentication/menu-items.service';

@Component({
    selector: 'app-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: [],
})
export class VerticalAppSidebarComponent implements OnDestroy {
    private rolesPermissionSub: Subscription;

    public config: PerfectScrollbarConfigInterface = {};
    public mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;
    public status = true;

    public itemSelect: number[] = [];
    public parentIndex = 0;
    public childIndex = 0;

    @Input() showClass: boolean = false;
    @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public media: MediaMatcher,
        public menuItems: MenuItems,
        private title: Title,
        private translate: TranslateService,
        private rolesPermissionService: RolesPermissionService
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.rolesPermissionSub = this.rolesPermissionService.dataSub.subscribe((data: any) => {
            this.updateMenuitems(data);
        });
        this.updateMenuitems();
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        if (this.rolesPermissionSub) this.rolesPermissionSub.unsubscribe();
    }

    updateMenuitems(data: any = this.rolesPermissionService.data) {
        let menu_items = data.get('menu_items');
        this.menuItems.updateDisplay(menu_items);
    }

    setClickedRow(i: number, j: number): void {
        this.parentIndex = i;
        this.childIndex = j;
    }

    subclickEvent(): void {
        this.status = true;
    }

    scrollToTop(): void {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0,
        });
    }

    handleNotify(data: string) {
        this.title.setTitle(this.translate.instant(data));
        if (window.innerWidth < 1024) {
            this.notify.emit(!this.showClass);
        }
    }

    createRouterLink(state: any, childitem_state: any = undefined, child_state: any = undefined) {
        // let result = ['/'];
        // if (state)
        //     if (Array.isArray(state)) result = [...result, ...state];
        //     else result = [...result, state];

        // if (childitem_state)
        //     if (Array.isArray(childitem_state)) result = [...result, ...childitem_state];
        //     else result = [...result, childitem_state];

        // if (child_state)
        //     if (Array.isArray(child_state)) result = [...result, ...child_state];
        //     else result = [...result, child_state];

        let result: string = '/';
        if (state)
            if (Array.isArray(state)) result += state.join('/');
            else result += state;
        if (childitem_state)
            if (Array.isArray(childitem_state)) result += '/' + childitem_state.join('/');
            else result += '/' + childitem_state;
        if (child_state)
            if (Array.isArray(child_state)) result += '/' + child_state.join('/');
            else result += '/' + child_state;

        // console.log('rerender router link' + result);

        return result;
    }
}
