import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { KhtcBctcTT99Service } from '../bao-cao-ban-khtc-bctc-tt99/khtc-bctc-tt-99.service';
import { AccountService } from '../common-services/account.service';
import { HostnameService } from '../common-services/hostname.service';
import { MENU_ITEMS, MENU_ITEMS_ORDER } from './menu-items.service.utils';
import { MCODES } from './roles.menu.utils';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
    badge?: BadgeItem[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

@Injectable({ providedIn: 'root' })
export class MenuItems {
    private _menuItems: Menu[] = [];
    private _menuItemsSub: Subject<Menu[]> = new Subject();

    get Menuitems() {
        return this._menuItems;
    }
    set Menuitems(input: Menu[]) {
        this._menuItems = input;
        this._menuItemsSub.next(this._menuItems);
    }

    get MenuitemsSub() {
        return this._menuItemsSub;
    }

    constructor(
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private khtcBctcTT99Service: KhtcBctcTT99Service
    ) {}

    ngOnDestroy() {}

    updateReceiverFileInternal(isHasNew: boolean) {
        // if (isHasNew) {
        //     if (this._menuItems && this._menuItems[1] && this._menuItems[1].children)
        //         this._menuItems[1].children[1].badge = [{ type: 'warning', value: 'new' }];
        // } else {
        //     if (this._menuItems && this._menuItems[1] && this._menuItems[1].children)
        //         delete this._menuItems[1].children[1].badge;
        // }
    }

    updateDisplay(items: string[]) {
        let ordered_items = MENU_ITEMS_ORDER.filter((item: string) => items.includes(item));
        let menu_items: any[] = [];

        for (let item of ordered_items) {
            menu_items.push(MENU_ITEMS.get(item));
        }

        this.Menuitems = menu_items;
    }
}
