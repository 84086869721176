import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = { state: 'dashboards', name: 'Dashboards', type: 'link', icon: 'av_timer' };

export const MENU_ITEMS_LUUTRU = {
    state: 'files',
    name: 'Files',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'filesdownloaded', name: 'Files Downloaded', type: 'link' }],
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'Account',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'editaccount', name: 'Edit Account', type: 'link' }],
};

export const BC_KHTC_HOI_NGHI_TAI_CHINH = {
    state: ['synthetic', 'bao-cao-hntc'],
    name: 'hoi_nghi_tai_chinh',
    type: 'link',
    icon: 'payments',
};

export const BC_KHTC_BAO_CAO_TAI_CHINH = {
    state: ['synthetic', 'bao-cao-tai-chinh'],
    name: 'bao_cao_ban_khtc_bctc_tt107',
    type: 'link',
    icon: 'attach_money',
};

export const BC_KHTC_BAO_CAO_TAI_CHINH_TT09 = {
    state: ['synthetic', 'bao-cao-tai-chinh-tt09'],
    name: 'bao_cao_ban_khtc_bctc_tt99',
    type: 'link',
    icon: 'attach_money',
};

export const BC_KHTC_BAO_CAO_QUYET_TOAN = {
    state: ['synthetic', 'bao-cao-quyet-toan'],
    name: 'bao_cao_ban_khtc_bcqt',
    type: 'link',
    icon: 'manage_search',
};

export const BC_KHTC_BAO_CAO_QUAN_TRI = {
    state: ['synthetic', 'bao-cao-quan-tri'],
    name: 'bao_cao_ban_khtc_bcqtr',
    type: 'link',
    icon: 'settings_suggest',
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.khtc_hntc, BC_KHTC_HOI_NGHI_TAI_CHINH],
    [MCODES.khtc_bctc, BC_KHTC_BAO_CAO_TAI_CHINH],
    [MCODES.khtc_bctc_tt99, BC_KHTC_BAO_CAO_TAI_CHINH_TT09],
    [MCODES.khtc_bcqt, BC_KHTC_BAO_CAO_QUYET_TOAN],
    [MCODES.khtc_bcqtr, BC_KHTC_BAO_CAO_QUAN_TRI],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.khtc_hntc,
    MCODES.khtc_bctc,
    MCODES.khtc_bctc_tt99,
    MCODES.khtc_bcqt,
    MCODES.khtc_bcqtr,
    MCODES.luutru,
    MCODES.taikhoan,
];
