import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { HostnameService } from './hostname.service';

export interface AuthencationResponse {
    code: string;
    token: string;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
    isLoginIn: boolean = false;
    code: string = '';
    username: string = '';
    apikey: string = 'user_key';
    attributes: any = {};
    token: string = '';
    subject: any;
    userRole: any;
    profile: any;

    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private keyCloakService: KeycloakService
    ) {}

    public get userValue(): boolean {
        return this.isLoginIn;
    }

    getMetadata() {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let attributes = keyCloakInstance.profile.attributes;

        let metadata: { ma_don_vi: string; mat_khau_don_vi: string; ma_don_vi_tai_tro: string | undefined } = {
            ma_don_vi: '',
            mat_khau_don_vi: '',
            ma_don_vi_tai_tro: undefined,
        };
        if (attributes.ma_don_vi) metadata.ma_don_vi = attributes.ma_don_vi[0];
        if (attributes.mat_khau_don_vi) metadata.mat_khau_don_vi = attributes.mat_khau_don_vi[0];
        if (attributes.ma_don_vi_tai_tro) metadata.ma_don_vi_tai_tro = attributes.ma_don_vi_tai_tro[0];
        return metadata;
    }

    getUserRoles(): string[] {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let userRoles: string[] = [];
        if (parseToken?.resource_access['webapp-khtc']) {
            userRoles = parseToken?.resource_access['webapp-khtc'].roles;
        }
        return userRoles;
    }

    updateUserdata() {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let clientId = keyCloakInstance.clientId;
        let userRole = [];

        if (parseToken?.resource_access['webapp-khtc']) {
            userRole = parseToken?.resource_access['webapp-khtc'].roles;
        }

        this.attributes = keyCloakInstance.profile.attributes;
        this.userRole = userRole;
        this.profile = keyCloakInstance.profile;
        this.subject = keyCloakInstance.subject;
    }

    async updateUserProfile() {
        let profile: any = await this.keyCloakService.loadUserProfile(true);
        this.profile = profile;
        this.attributes = profile.attributes;
    }

    changeUserInfo(email = '', firstName = '', lastName = '') {
        let metadata = this.getMetadata();
        return this.httpClient
            .put<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/account-info/change-info',
                {
                    metadata: metadata,
                    account_data: { email: email, firstName: firstName, lastName: lastName },
                    old_account_data: {
                        email: this.profile.email,
                        firstName: this.profile.firstName,
                        lastName: this.profile.lastName,
                    },
                    _id: this.subject,
                },
                {
                    headers: {
                        apikey: this.apikey,
                    },
                }
            )
            .toPromise();
    }

    getSubject() {
        return this.keyCloakService.getKeycloakInstance().subject;
    }

    getProfile() {
        return this.keyCloakService.getKeycloakInstance().profile;
    }

    getRoles(): string[] {
        return this.keyCloakService.getUserRoles();
    }

    getAPIKey() {
        return 'university_key';
    }

    getCode() {
        return this.getMetadata().ma_don_vi;
    }

    getToken(): string | undefined {
        return this.keyCloakService.getKeycloakInstance().token;
    }
}
