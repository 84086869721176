// DASHBOARD ELEMENTS
export const DASHBOARD_ELEMENTS = {
    TOP_CARD_KHTC_HNTC: 'dashboard-top-card-khtc-hntc',
};

// ROLES
export const DASHBOARD_ROLES: Map<string, any> = new Map([
    [
        'admin',
        {
            elements: [DASHBOARD_ELEMENTS.TOP_CARD_KHTC_HNTC],
        },
    ],
    // KHTC HNTC
    [
        'vnuhcm.khtc.hntc.chuyenvien',
        {
            elements: [DASHBOARD_ELEMENTS.TOP_CARD_KHTC_HNTC],
        },
    ],
    [
        'vnuhcm.khtc.hntc.lanhdao',
        {
            elements: [DASHBOARD_ELEMENTS.TOP_CARD_KHTC_HNTC],
        },
    ],

    // KHTC XDDT
    [
        'vnuhcm.khtc.xddt.chuyenvien',
        {
            elements: [],
        },
    ],
    [
        'vnuhcm.khtc.xddt.lanhdao',
        {
            elements: [],
        },
    ],

    // KHTC GDT
    [
        'vnuhcm.khtc.gdt.chuyenvien',
        {
            elements: [],
        },
    ],
    [
        'vnuhcm.khtc.gdt.lanhdao',
        {
            elements: [],
        },
    ],

    // KHTC QT
    [
        'vnuhcm.khtc.qt.chuyenvien',
        {
            elements: [],
        },
    ],
    [
        'vnuhcm.khtc.qt.lanhdao',
        {
            elements: [],
        },
    ],

    // KHTC TTQT
    [
        'vnuhcm.khtc.ttqt.chuyenvien',
        {
            elements: [],
        },
    ],
    [
        'vnuhcm.khtc.ttqt.lanhdao',
        {
            elements: [],
        },
    ],
]);

// FUNCTIONS
export function dashboard_check_for_role(roles: string[]) {
    let elements: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = DASHBOARD_ROLES.get(role);
        if (!role_info) return;
        role_info.elements.forEach((item: string) => elements.add(item));
    });

    return {
        elements: [...elements],
    };
}
