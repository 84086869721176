// CONSTABCE
export const BC_KHTC_HNTC_CODES = {
    tt_bao_cao: {
        // ban_thao: 'ban_thao', // Bản thảo
        // trinh_lanh_dao: 'trinh_lanh_dao', // Trình lãnh đạo đơn vị
        // yeu_cau_sua: 'yeu_cau_sua', // Yêu cầu sửa
        da_nop: 'da_nop', // Đã nộp báo cáo
        da_huy: 'da_huy', // Đã hủy
        trinh_lanh_dao_ban: 'trinh_lanh_dao_ban', // Trình lãnh đạo ban
        da_duyet: 'da_duyet', // Đã duyệt
    },
    actions: {
        checked: 'checked', // Xem
        xem: 'xem', // Xem
        // them: 'them', // Thêm
        // tao_moi: 'tao_moi', // Tạo mới từ báo cáo cũ
        // xoa: 'xoa', // Xóa
        // sua: 'sua', // Sửa
        // trinh_lanh_dao: 'trinh_lanh_dao', // Trình lãnh đạo đơn vị
        // yeu_cau_sua: 'yeu_cau_sua', // Yêu cầu sửa
        trinh_lanh_dao_ban: 'trinh_lanh_dao_ban', // Trình lãnh đạo ban
        khong_duyet_bao_cao: 'khong_duyet_bao_cao', // Trình lãnh đạo ban
        duyet_bao_cao: 'duyet_bao_cao', // Nộp báo cáo
    },
};

// ROLES
export const BCTH_BCKHTC_HNTC_ROLES: Map<string, any> = new Map([
    [
        'vnuhcm.khtc.hntc.chuyenvien',
        {
            query: Object.values(BC_KHTC_HNTC_CODES.tt_bao_cao),
            global_actions: [],
            // prettier-ignore
            actions: {
                trinh_lanh_dao_ban: [],
                da_nop: [BC_KHTC_HNTC_CODES.actions.checked, BC_KHTC_HNTC_CODES.actions.khong_duyet_bao_cao, BC_KHTC_HNTC_CODES.actions.duyet_bao_cao],
                da_huy: [],
                da_duyet: [],
            },
        },
    ],
    [
        'vnuhcm.khtc.hntc.lanhdao',
        {
            query: Object.values(BC_KHTC_HNTC_CODES.tt_bao_cao),
            global_actions: [],
            // prettier-ignore
            actions: {
                trinh_lanh_dao_ban: [],
                da_nop: [BC_KHTC_HNTC_CODES.actions.checked, BC_KHTC_HNTC_CODES.actions.khong_duyet_bao_cao, BC_KHTC_HNTC_CODES.actions.duyet_bao_cao],
                da_huy: [],
                da_duyet: [],
            },
        },
    ],
]);

// FUNCTIONS
export function bcth_khtc_hntc_check_for_role(roles: string[]) {
    let queries: Set<string> = new Set();
    let actions = {
        da_nop: new Set(),
        da_huy: new Set(),
        trinh_lanh_dao_ban: new Set(),
        da_duyet: new Set(),
    };
    let global_actions: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = BCTH_BCKHTC_HNTC_ROLES.get(role);
        if (!role_info) return;
        role_info.query.forEach((item: string) => queries.add(item));
        role_info.actions.da_nop.forEach((item: string) => actions.da_nop.add(item));
        role_info.actions.da_huy.forEach((item: string) => actions.da_huy.add(item));
        role_info.actions.trinh_lanh_dao_ban.forEach((item: string) => actions.trinh_lanh_dao_ban.add(item));
        role_info.actions.da_duyet.forEach((item: string) => actions.da_duyet.add(item));
        role_info.global_actions.forEach((item: string) => global_actions.add(item));
    });

    return {
        query: Array.from(queries),
        actions: {
            da_nop: Array.from(actions.da_nop),
            da_huy: Array.from(actions.da_huy),
            trinh_lanh_dao_ban: Array.from(actions.trinh_lanh_dao_ban),
            da_duyet: Array.from(actions.da_duyet),
        },
        global_actions: Array.from(global_actions),
    };
}
